import { Injectable, ErrorHandler, inject } from '@angular/core';
import { ToastService } from '@core/services';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  private readonly toastService: ToastService = inject(ToastService);

  handleError(error: Error) {
    this.toastService.present(
      'danger',
      error.message || 'An unexpected error occurred.', 'bottom', 5000
    );
  }
}
