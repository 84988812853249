import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@core/services';
import { map, take, tap } from 'rxjs';

export const AuthGuard = () => {
  const router = inject(Router);
  const authService = inject(AuthService);

  return authService.authState$.pipe(
    take(1),
    map((user) => !!user),
    tap((loggedIn) => {      
      if (!loggedIn) {
        router.navigateByUrl('/auth/login');
      }
    })
  );
};
