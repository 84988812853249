import {
  AfterViewInit,
  Component,
  EnvironmentInjector,
  OnInit,
  effect,
  inject,
} from '@angular/core';
import { UserV1Service } from '@core/http/v1';
import { UserProfileDto } from '@core/models';
import { AuthService, LocationService, PlatformService } from '@core/services';
import { UserStore } from '@core/stores';
import { IonRouterOutlet } from '@ionic/angular/standalone';
import { getState } from '@ngrx/signals';
import { untilDestroyed } from '@shared/utilities';
import { User } from 'firebase/auth';
import { filter, switchMap, tap } from 'rxjs';
import { SpeechRecognition } from "@capacitor-community/speech-recognition";

//  call Swiper's register function to globally register Swiper's custom elements.
// This should only be done once
import { register } from 'swiper/element/bundle';

register();

@Component({
  standalone: true,
  imports: [IonRouterOutlet],
  selector: 'resqc-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit, AfterViewInit {
  private _user!: User;
  public darkMode: boolean = false;
  public loading: boolean = true;
  public speechRecog = SpeechRecognition;

  private readonly authService: AuthService = inject(AuthService);
  private readonly usersService: UserV1Service = inject(UserV1Service);
  private readonly locationService: LocationService = inject(LocationService);
  private readonly platformService: PlatformService = inject(PlatformService);
  private readonly userStore = inject(UserStore);
  private readonly takeUntilDestroyed = untilDestroyed();

  constructor(public environmentInjector: EnvironmentInjector) {
    effect(() => {
      // 👇 The effect will be re-executed whenever the state changes.
      const state = getState(this.userStore);
      console.log('Dev: User Profile State Changed', state);
    });
  }

  ngOnInit(): void {
    this.authService.authState$
      .pipe(
        filter((state) => !!state),
        tap((state) => (this._user = state as User)),
        switchMap(() => {
          return this.usersService.getUserProfile();
        }),
        this.takeUntilDestroyed()
      )
      .subscribe((userProfile: UserProfileDto) => {
        // Update the user profile in the store
        this.userStore.updateProfile(this._user, userProfile);
      });

    this.locationService.runAppPermissions();
    if (this.platformService.isMobile()) {
      this.speechRecog.requestPermissions();
    }
  }

  ngAfterViewInit(): void {
    // Switch Theme
    const theme = sessionStorage.getItem('theme');
    this.darkMode = theme === 'dark' ? true : false;
    this.loading = true;
    setTimeout(() => {
      this.loading = false;
      this.changeTheme();
    }, 1500);
  }

  changeTheme() {
    const theme = !this.darkMode ? 'initial' : 'dark';
    const divContainer = document.querySelectorAll('[data-theme]') as any;
    divContainer.forEach((item: any) => {
      item.setAttribute('data-theme', theme);
    })
    sessionStorage.setItem('theme', theme)
  }
}
