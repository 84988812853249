<div data-theme="initial">
  <ion-router-outlet></ion-router-outlet>
</div>
@if(loading){
<div class="bg-base-100 absolute w-full h-full">
  <div class="flex items-center justify-center h-inherit">
    <span class="loading loading-spinner loading-lg"></span>
  </div>
</div>
}
