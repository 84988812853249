import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { isEmpty } from 'lodash';

export const OnBoardingGuard = () => {
  const router = inject(Router);

  const onBoarding = sessionStorage.getItem('onBoarding');
  if (isEmpty(onBoarding)) {
    // Redirect to the login page
    return true;
  }
  router.navigateByUrl('/auth/login');
  return false;
};