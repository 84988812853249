import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@core/services';
import { map, take, tap } from 'rxjs';

export const LoginGuard = () => {
  const router = inject(Router);
  const authService = inject(AuthService);

  return authService.authState$.pipe(
    take(1),
    map((user) => !user), // Return true if user is NOT logged in, allowing navigation
    tap((isNotLoggedIn) => {
      if (!isNotLoggedIn) {
        // If the user is logged in (meaning isNotLoggedIn is false), redirect to home
        router.navigateByUrl('/main/home');
      }
    })
  );
};
