import { bootstrapApplication } from '@angular/platform-browser';
import { appConfig } from './app/app.config';
import { AppComponent } from './app/app.component';
import { environment } from './environments/environment';
import { enableProdMode } from '@angular/core';
import { Settings } from 'luxon';

Settings.defaultZone = 'Asia/Manila'; // Sets the default time zone
Settings.defaultLocale = 'en'; // Sets the default locale

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, appConfig).catch((err) =>
  console.error(err)
);
