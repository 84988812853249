import { Route } from '@angular/router';
import { AuthGuard, LoginGuard, OnBoardingGuard } from '@core/guards';

export const appRoutes: Route[] = [
  {
    path: 'splash-screen',
    canActivate: [OnBoardingGuard],
    loadComponent: () =>
      import('./pages/splash-screen/splash-screen.page').then(
        (m) => m.SplashScreenPage
      ),
  },
  {
    path: 'auth',
    canActivate: [LoginGuard],
    loadChildren: () =>
      import('./pages/authentication/auth.routes').then((m) => m.authRoutes),
  },
  {
    path: 'main',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/main/main.routes').then((m) => m.mainRoutes),
  },
  {
    path: 'settings',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/settings/settings.routes').then((m) => m.settingRoutes),
  },
  {
    path: '',
    redirectTo: 'splash-screen',
    pathMatch: 'full',
  },
];
